@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// Reset CSS
ul {
  margin-bottom: 0;
  list-style: none;
}

a {
  line-height: normal;
  &:hover {
    text-decoration: none;
  }
}
.loding_spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

// Common Style

.blackbtn-color {
  padding: 8px 15px;
  font-size: 20px;
  background-color: #3e403d;
  color: #fff;
  border-radius: 10px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
}

// Header
header {
  background-color: #3e403d;
}
.header-container {
  max-width: 1600px;
  margin: 0px auto;
  padding-left: 15px;
  padding-right: 15px;
}
.header-outerwrap {
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
  }
  .header-logo {
    a {
      display: inline-block;
      img {
        max-width: 200px;
      }
    }
  }
  .header-middlemenu {
    ul {
      display: flex;
      list-style: none;
      margin-bottom: 0;
      li {
        margin: 0px 10px;
        a {
          color: #fff;
          font-size: 26px;
          font-weight: 500;
          padding: 5px 10px;
          display: inline-block;
          line-height: 30px;
        }
      }
    }
  }
  .header-rightmenu {
    ul {
      display: flex;
      list-style: none;
      margin-bottom: 0;
      li {
        margin: 0px 10px;
        a {
          color: #fff;
          font-size: 26px;
          font-weight: 500;
          padding: 5px 20px;
          display: inline-block;
          line-height: 30px;
          &.colorbtn {
            background-color: #b595c5;
            border-radius: 10px;
            padding: 6px 20px 6px;
          }
        }
      }
    }
  }
}

// Footer
.footer {
  background-color: #3e403d;
  padding: 30px 0px;
}
.footer-container {
  max-width: 1600px;
  margin: 0px auto;
  padding-left: 15px;
  padding-right: 15px;
}

.copyright-content {
  margin-top: 50px;
  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0px;
    text-align: center;
  }
}

.footer-leftlogo {
  h5 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
  }
  .footlogo {
    display: inline-block;
    margin-bottom: 2px;
    img {
      max-width: 200px;
    }
  }
  p {
    margin-bottom: 0;
    a {
      color: #fff;
      font-size: 14px;
    }
  }
}

.footer-menulink {
  h5 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    display: inline-block;
  }
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      margin-bottom: 5px;
      a {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
      }
    }
  }
}

* {
  padding: 0;
  margin: 0;
  // overflow-x: hidden;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  @media (min-width: 1200px) {
    max-width: 90%;
    width: 100%;
  }
}

// .container {
//   @media (min-width: 1200px) {
//     max-width: 1400px;
//     width: 100%;
//     padding-left: 20px;
//     padding-right: 20px;
//   }
// }